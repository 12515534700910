import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Image from "gatsby-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Icon from "./icon"
import { ScrollTopButton } from "./buttons"

import "./layout.css"
import Navbar from "./navigation/navBar";
import ContactInfo from "./contactInfo";

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            homeTitle,
            proceduresTitle,
            aboutTitle,
            blogTitle,
            pricingTitle
          }
        }
        file(relativePath: {eq: "home/baseInfo.json"}) {
          childHomeJson {
            title
            telephone_pl
            telephone_en
            email
            street
            city
            open_days
            open_hours
            copyright
            facebook
            instagram
            tiktok
          }
        }
        logoImage: file(relativePath: {eq: "images/logo.png"}) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        newsletterBackground: file(relativePath: {eq: "images/newsletter_background.png"}) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  const breakpoints = useBreakpoint();
  const baseInfo = data.file.childHomeJson
  return (
    <React.Fragment>
      {breakpoints.sm ? null : <ScrollTopButton showBelow={250}/>}
      <header className='header-main'>
        <div className='header-first-row'>
          <div className='header-tabs'>
            <Link className='logo' to={"/"}><Image fluid={data.logoImage.childImageSharp.fluid} loading="eager"/></Link>
            <Navbar data={data.site.siteMetadata} baseInfo={baseInfo}/>
          </div>
          <div className='header-info'>
            <ContactInfo baseInfo={baseInfo} className='hide-on-mobile' />
            <div className='header-social'>
              <a href={baseInfo.facebook} target="_blank noreferrer" aria-label='facebook'><Icon icon='facebook2' className='header-icons-social'/></a>
              <a href={baseInfo.instagram} target="_blank noreferrer" aria-label='instagram'><Icon icon='instagram' className='header-icons-social'/></a>
              <a href={baseInfo.tiktok} target="_blank noreferrer" aria-label='tik-tok'><Icon icon='tiktok' className='header-icons-social'/></a>
            </div>
          </div>
        </div>
        <div className='header-second-row'>
          <h2>{baseInfo.title}</h2>
        </div>
      </header>
      {children}
      <footer id='footer'>
        <div className='footer-black'>
          <div className='footer-newsletter'>
            <Image
              fluid={data.newsletterBackground.childImageSharp.fluid}
              className='footer-newsletter-background-img'
              style={{position: "absolute"}}
              imgStyle={{ objectFit: "100% 100%" }}
            />
            <div className='footer-newsletter-text'>
              <div>Bądź na bieżąco</div>
              <div>Oglądaj najnowsze historie prosto z Turcji</div>
              <div>
                <a href={baseInfo.tiktok} target="_blank noreferrer" aria-label='tik-tok'>Odwiedź naszego TikToka<Icon icon='chevron-thin-right' style={{width: '1.4vw'}}/></a>
              </div>
            </div>
          </div>
          <div className='footer-info-wrapper'>
            <div className='footer-info'>
              <div>
                <div>Kontakt</div>
                <div><a href={`https://wa.me/${baseInfo.telephone_pl.replace(/\+| /g, '')}`} target="_blank noreferrer"><Icon icon='whatsapp' className='footer-icons'/>{baseInfo.telephone_pl} <span role='img' aria-label='polish flag'>&#127477;&#127473;</span></a></div>
                <div><a href={`https://wa.me/${baseInfo.telephone_en.replace(/\+| /g, '')}`} target="_blank noreferrer"><Icon icon='whatsapp' className='footer-icons'/>{baseInfo.telephone_en} <span role='img' aria-label='british flag'>&#127468;&#127463;</span> <span role='img' aria-label='german flag'>&#127465;&#127466;</span></a></div>
                <div><a href={`mailto:${baseInfo.email}`}><Icon icon='envelop' className='footer-icons'/>{baseInfo.email}</a></div>
              </div>
              {/*<div>*/}
              {/*  <div>Adres</div>*/}
              {/*  <div><Icon icon='location' className='footer-icons'/>{baseInfo.street}</div>*/}
              {/*  <div><Icon icon='clock' className='footer-icons-dummy'/>{baseInfo.city}</div>*/}
              {/*</div>*/}
              <div>
                <div>Godziny otwarcia</div>
                <div><Icon icon='clock' className='footer-icons'/>{baseInfo.open_days}</div>
                <div><Icon icon='clock' className='footer-icons-dummy'/>{baseInfo.open_hours}</div>
              </div>
              <div>
                <div>Media społecznościowe</div>
                <div className='footer-social'>
                  <a href={baseInfo.facebook} target="_blank noreferrer" aria-label='facebook'><Icon icon='facebook2' className='footer-icons-social'/></a>
                  <a href={baseInfo.instagram} target="_blank noreferrer" aria-label='instagram'><Icon icon='instagram' className='footer-icons-social'/></a>
                  <a href={baseInfo.tiktok} target="_blank noreferrer" aria-label='tik-tok'><Icon icon='tiktok' className='footer-icons-social'/></a>
                </div>
              </div>
            </div>
          </div>
          <Image
            fluid={data.bannerShadow.childImageSharp.fluid}
            className='background-rounded-shadow'
            style={{ position: "absolute" }}
            loading="eager"
          />
        </div>
        <div className='footer-copyright'>
          <div>&copy; {new Date().getFullYear()} {baseInfo.copyright}</div>
        </div>
      </footer>
    </React.Fragment>
  )
}
