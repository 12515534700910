import Icon from "./icon";
import React from "react";

const ContactInfo = ({baseInfo, className}) => {
    return (
        <div className={`contact-info-wrapper ${className}`}>
            <div className='contact-info'>
                <div>
                    <div>
                        <a href={`https://wa.me/${baseInfo.telephone_pl.replace(/\+| /g, '')}`} target="_blank noreferrer">
                            <Icon icon='whatsapp' className='header-icons'/>{baseInfo.telephone_pl} <span style={{fontSize: '12px'}} role='img' aria-label='polish flag'>&#127477;&#127473;</span>
                        </a>
                    </div>
                    <div>
                        <a href={`https://wa.me/${baseInfo.telephone_en.replace(/\+| /g, '')}`} target="_blank noreferrer">
                            <Icon icon='whatsapp' className='header-icons'/>{baseInfo.telephone_en} <span style={{fontSize: '12px'}} role='img' aria-label='british flag'>&#127468;&#127463;</span> <span style={{fontSize: '12px'}} role='img' aria-label='german flag'>&#127465;&#127466;</span>
                        </a>
                    </div>
                    <div>
                        <a href={`mailto:${baseInfo.email}`}><Icon icon='envelop' className='header-icons'/>{baseInfo.email}</a>
                    </div>
                </div>
                {/*<div>*/}
                {/*  <div><Icon icon='location' className='header-icons'/>{baseInfo.street}</div>*/}
                {/*  <div><Icon icon='clock' className='header-icons-dummy'/>{baseInfo.city}</div>*/}
                {/*</div>*/}
                <div>
                    <div><Icon icon='clock' className='header-icons'/>{baseInfo.open_days}</div>
                    <div><Icon icon='clock' className='header-icons-dummy'/>{baseInfo.open_hours}</div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo