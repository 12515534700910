import React from "react"
import PartialNavLink from "./partialNavLink";
import {Link} from "gatsby";

const NavbarLinks = ({data}) => {
    return (
        <>
            <Link activeClassName="active" to={"/"}><h3>{data.homeTitle}</h3></Link>
            <PartialNavLink to={"/procedures"}><h3>{data.proceduresTitle}</h3></PartialNavLink>
            <PartialNavLink to={"/about"}><h3>{data.aboutTitle}</h3></PartialNavLink>
            <PartialNavLink to={"/blog"}><h3>{data.blogTitle}</h3></PartialNavLink>
            <PartialNavLink to={"/pricing"}><h3>{data.pricingTitle}</h3></PartialNavLink>
        </>
    )
}

export default NavbarLinks