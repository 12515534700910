import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import NavbarLinks from "./navLink"
import ContactInfo from "../contactInfo";
import {useOutsideClickHandler} from "../../utils/outsideClickHandler";

const Navigation = styled.nav`
  z-index: 2;
  height: 100%;
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  ::after {
    background-color: #000;
    opacity: 0.8;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 80%;
    justify-content: flex-start;
    gap: 5vh;
    padding: 5vh 0;
    background-color: #fff;
    transition: all 0.3s ease-in;
    left: ${props => (props.open ? "-100%" : "0")};
    height: calc(100% - var(--header-height));
  }
`

const Hamburger = styled.div`
  font-weight: bold;
  width: 22px;
  height: 2px;
  background-color: #333;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => props.open ? "rotate(-45deg)" : "inherit"};

  ::before,
  ::after {
    width: 22px;
    height: 2px;
    background-color: #333;
    content: "";
    position: absolute;
    transition: all .3s linear;
  }

  ::before {
    transform: ${props => props.open ? "rotate(-90deg) translate(-7px, 0px)" : "rotate(0deg)"};
    top: -7px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 7px;
  }
`
const Navbar = ({data, baseInfo}) => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    const wrapperRef = useRef(null);
    useOutsideClickHandler(wrapperRef, () => setNavbarOpen(false));
    return (
        <Navigation ref={wrapperRef} className='navbar'>
            <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
                {navbarOpen ? <Hamburger open/> : <Hamburger/>}
            </Toggle>
            <Navbox open={!navbarOpen}>
                <NavbarLinks data={data}/>
                <ContactInfo baseInfo={baseInfo} className='show-on-mobile'/>
            </Navbox>
        </Navigation>
    )
}

export default Navbar