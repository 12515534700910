import React, { useState, useEffect, useCallback } from "react"
import Icon from "./icon"

import "./buttons.css"

const Button = ({ ...props }) => {
  const type = props.type ? props.type : "black"
  return React.createElement(props.as ? props.as : "button", {
    ...props,
    className: `custom-button ${type}${props.className ? " " + props.className : ""}`
  }, ...props.children)
}

const HalfCircleButton = ({ ...props }) => {
  return <button {...props} name='scroll down' aria-label="scroll down" className={`half-circle-button ${props.className ? " " + props.className : ""}`}>{props.children}</button>
}

const BackgroundScrollButton = ({ elementId }) => {
  return (
    <HalfCircleButton className='background-scroll-button' onClick={() => document.getElementById(elementId).scrollIntoView({ behavior: "smooth" })}>
      <Icon icon='chevron-thin-down'/>
    </HalfCircleButton>
  )
}

const ScrollTopButton = ({ showBelow }) => {
  const [show, setShow] = useState(!showBelow)

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }, [showBelow, show])

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  }, [showBelow, handleScroll])

  return (
    <button
      name='scroll top'
      aria-label='scroll top'
      className={"scroll-top-button" + (show ? " active" : "")}
      onClick={handleClick}
    ><Icon icon='chevron-thin-up'/></button>
  )
}

export default Button
export {
  HalfCircleButton,
  BackgroundScrollButton,
  ScrollTopButton
}
